/* div {
    border: 1px solid red;
} */

.contactContainer {

    overflow: auto;
    height: 100vh;
    width: 100%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 2px solid rgb(220, 223, 226);
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

.contactContainerInsideFolder {

    overflow: scroll;
    height: 85vh;
    width: 100%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 2px solid rgb(220, 223, 226);
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

.mailContainer {
    box-sizing: border-box;
    border-radius: 0.5rem;
    margin: 1rem 0.5rem !important;
    background-color: rgb(220, 223, 226);
    /* height: 65vh; */
    overflow: auto;
}

.box {
    border: 1px solid gray;
    background-color: azure;
}

.inboxItem {
    background-color: rgb(179, 179, 179);
    color: white;
    border-radius: 5px;
    color: black;
    cursor: pointer;
}

.leftNavSelected {
    border-top: 1px solid lightgray;
    height: 7vh;
}

.leftNavUnselected {
    border-top: 1px solid lightgray;
    height: 7vh;
}

.leftNavUnselectedNoTop {
    border-top: 1px solid lightgray;
    height: 7vh;
}

.spinnerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.spinnerInside {
    margin-left: 45%;
    margin-top: 300px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(100, 96, 96, 0.5);
}
.slider-container ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 1px rgba(100, 96, 96, 0.6);
}

.slider-container ::-webkit-scrollbar{
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
}

.slider-container ::-webkit-scrollbar-track {
    background-color:rgba(255, 255, 255, 0.7);
  }

.contact-left-navitem {
    border-top: 1px solid lightgray;
}

@media screen and (min-width:768px) {
    .contact-menu-icon {
        visibility: hidden;
    }

    .contact-left-navitem {
        border-top: 1px solid lightgray;
        height: 10v;
    }
}