.login_container {
    display: flex;
    padding: 0rem !important;
    margin: 0rem !important;
    position: relative;
    z-index: 11;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow-y: hidden;
    background-color: #2e3191;
}

.input-wrapper{
    padding : 20px 0px;
    width : 100%;
}

.gobackl{
background-color: transparent;
color: #fff;
border:transparent;
font-family: apotek-comp, sans-serif;
letter-spacing: 0.8px;
font-size: 20px;
}

.logo {
    width: 16rem
}

.logo2 {
    width: 9rem;
   align-self: right;
}

.login_fields {
    color: white;
    background-color: #2e3191;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: space-around; 
}
.login_fields .row {
    justify-content: center;
    margin: 0px !important;
}

.btn{
    /* background-image: url("./assets/hak12-securityportal-textfield.svg") !important;
    background-repeat: no-repeat;
    background-size: 100%; */
    padding: 0px !important;
    min-width: 135px;
}
.btn-wrapper .btn-group{
    padding : 0px;
}
.footer-logo{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding : 10px 20px 20px 10px;
    position: absolute;
    bottom: 5%;
    z-index: -1;
}
/*Sixth Row*/

.login_fields>div:nth-child(6) {
    color: rgb(248, 58, 121);
    text-decoration: underline;
}

.login_input {
    border-width: 2px;
    border-style: solid;
    border-radius: 8px;
    border-color: #2e3191;
    font-weight: 800;
    width: 100%;
    font-size: 1rem;
    padding: 0.4rem 0.5rem; 
    color: #2e3191;
    background-color: white;
    margin-bottom: 10px; 
    font-family: apotek-comp, sans-serif;
    font-weight: 200;
    letter-spacing: 0.8px;
    font-size: 19px;
    
} 

.errormessage{
    height : 55px;
}
.errormessage.user{
    height: auto;
    justify-content: flex-start;
}
.errormessage.hints{
    margin: 0 auto !important;
    height : auto !important;
}

.login_input:disabled {
    color: #2e3191;
}

/* .login_input::placeholder {
    color: #babbce;
} */

.login_btn {
    border-style: solid;
    border-width: 2px;
    border-color: #2e3191;
    color: #2e3191;
    /* padding: 0.5rem 1rem; */
    border-radius: 2rem;
    margin-right: 5px;
    
}

.cancel_btn {
    border-style: solid;
    border-width: 2px;
    border-color: #2e3191;
    color: #2e3191;
    /* padding: 0.5rem 1rem; */
    border-radius: 2rem;
}

.login_close {
    cursor: pointer;
    width: 1rem;
    position: absolute;
    margin-left: calc(-100% + 4rem);
}

.close_button {
    cursor: pointer;
    width: 1.5rem;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    z-index: 9;
}
.login-btn-wrapper .btn{
    background-color: #fff;
    font-family:  apotek-comp, sans-serif;
    color : #2e3191;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin : 5px;
    font-size: 18px;
}
.errormessage{
    font-family: apotek-comp, sans-serif;
    font-weight: 200;
    letter-spacing: 0.8px;
    font-size: 19px;
    text-align: center;
}
.login-btn-wrapper .btn.login_btn{
    margin-right: 0px;
}

@media screen and (max-width:1023px) {
    .login_input {
        border-radius: 1px;
    }
    .login_fields {
        border-radius: 0px;
    }
    .login_btn {
        border-radius: 0.2rem;
    }
    .cancel_btn {
        border-radius: 0.2rem;
    }
    /* fourth row */
    .login_fields>div:nth-child(4) {
        justify-content: center;
        margin-left: 0%;
    }
    .login_fields>div:nth-child(4)>span:nth-child(1) {
        display: none;
    }
    .login_fields>div:nth-child(4)>span:nth-child(2) {
        color: #2e3191;
        font-weight: 400;
    }
}