.slider-container{
    display: block;
    text-align: center;
    padding-top:30px;
}
.main {
    width: 55%;
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.main .item{
    width:100%;
}
.main img{
    width:100%;
    object-fit: cover;
}
.main .icon{
    display: inline-block;
    width: 40px;
    height: 40px;
    padding: 0px;
    background-size: 100%;
    top:auto;
    cursor: pointer;
    z-index: 2;
}
.main .icon.prev{
    position: absolute;
    left : 0;
}
.main .icon.next{
    position:absolute;
    right: 0;
}

.main .alice-carousel__wrapper{
    max-height: 320px;
}
.thumbs .icon{
    display: inline-block;
    width: 35px !important;
    height: 35px;
    padding: 0px;
    background-size: 100%;
    top:30%;
    cursor: pointer;
}
.thumbs .icon.prev{
    position: absolute;
    left : 0;
}
.thumbs .icon.next{
    position:absolute;
    right: 0;
}

.thumb-container .thumbs{
    width: 63%;
    margin: 30px auto;
    padding: 0 40px;
    position: relative;
    display: flex;
    align-items: center;
}
.thumb-container .thumbs .alice-carousel{
    position: relative;
    width: 100%;
    margin: auto;
    direction: ltr;
}

.thumb-container .thumbs .alice-carousel__wrapper{
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    width: 100%;
    height: auto;
}
.thumb-container .thumbs .alice-carousel__stage{
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    transform-style: flat;
    -webkit-transform-style: flat;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
.thumb-container .thumbs .alice-carousel__stage-item{
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    vertical-align: top;
    white-space: normal;
    line-height: 0;
}
.thumb-container .thumbs .alice-carousel__stage-item.__active img{
    border : 2px solid #000;
}
.thumb-container .thumbs .item{
    padding: 10px;
}
.thumb-container .thumbs .item img{
    width:100px !important;
    height: 75px;
    cursor: pointer;
}
.thumb-container .thumbs li,.thumb-container .thumbs .item, .thumb-container .thumbs .thumb{
    display: inline-block !important;
}
.thumb-container .thumbs li{
    width: auto !important;
}
.slider-header {
    padding: 2px;
    margin: 0 auto;
    display: block;
    width: 55%;
    background-color: #2a2c7a;
    color : #ffffff;
}
.slider-header label{
    color: #ffffff;
    margin-bottom: 0px;
    text-align: left;
    display: block;
}
.slider-count{
    color : #2a2c7a;
    font-weight: 700;
    text-align: left;
    width: 55%;
    margin : 0 auto;
}
.slider-container .btn{
    min-width:auto;
}
.zoom-container img{
    width: 100%;
}
.zoom-container {
    position: absolute;
    z-index: 1;
    height: 100%;
    overflow: auto;
    border : 1px solid #2a2c7a;
}
.menu.disable{
    cursor: not-allowed;
    pointer-events: none;
}
.menu.enable{
    cursor: pointer;
}
@media screen and (max-width:1200px) {
    .main {
        border : none;
    }
}

@media screen and (max-width:1023px) {
    .icon.next{
        width: 20px;
        height: 20px;
    }
    .icon.prev{
        width: 20px;
        height: 20px;
    }
}

@media only screen and (max-width: 768px) {
   .slider-count, .slider-header, .main {
       width: 80%;
   }
   .thumb-container .thumbs{
       width : 90%;
   }
}
  