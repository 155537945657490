.menu {
    padding: 7px;
    border : none;
    background-color : #ffffff;
    border-style: none;
    color : #2a2c7a;
    width:  90%;
    border-radius: 8px;
    margin-bottom: 30px;
    font-size : 18px;
    font-family: apotek-comp, sans-serif;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0.5px;
}
.section_container{
    background-color: #ffffff;
    max-width: 100% !important;
    padding: 0px !important;
    font-family: apotek-comp, sans-serif;
    font-weight: 200;
    letter-spacing: 0.8px;
    font-size: 19px;
}
.section-header{
    background-color: #2a2c7a;
    padding: 10px;
    text-align: center;
}

.section-headerl{
    background-color: #221F20;
    padding: 10px;
    
}

.header-wrapper{
    position: relative;
}
.header-wrapper .goback{
    position:absolute;
    bottom:0;
    left:0;
    border-style: none;
    background-color: transparent;
    color: #ffffff;
    letter-spacing: 0.9px;
    z-index: 9;
}

 .header-wrapper .accountdetails_container{
    position: relative;
    bottom: 0;
    right: 0;
    color: #ffffff;
    letter-spacing: 0.9px;
    text-align: right;
 }

 .header-wrapper .accountdetails_container label{
  margin-bottom: 0;
  margin-top: 0;
 }

