.landingContainer {
    background-color: #DFDDDE;
    position: relative;
}

.landingLoginBox {
    margin: 0;
    position: absolute;
    background-color: #454545;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    width: 250px;
    transform: translate(-50%, -50%);
}

.error {
    color: red
}

.gray-logo-background {
    background-color: #C1B3D8;
    background-image: url("https://cdn.huntakiller.com/huntakiller/s7/graylogo.svg");
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}

.hak-contact-icon {
    height: 15vh;
    position: absolute;
    right: 4vh;
    bottom: 7vh;
    z-index: 0
 }

.hak-contact-icon img {
    max-height: 100%;
    height: 100%;
    cursor: pointer;
}

.hak-icon {
    display: flex;
    height: 18vh;
    padding: 0px;
    justify-content: flex-end;
    position:relative;
}

.hak-icon-label {
    text-align: center;
    word-wrap: break-word;
}

.hak-icon img {
    max-height: 100%;
    height: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}

.hak-icon-container {
    color: white;
}

.hak-icon-ul{
    margin-right: 0px !important;
    margin : 0px !important;
}
.hak-modal-folder .hak-icon-ul img[alt="Note"]{
    width: 100%;
    height: 86%;
}
.hak-modal-folder .hak-icon-ul img[alt="Sofia's Journal 3"]{
    margin: 0px 10px;
}

.read-icon{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ff0000;
    position:absolute;
    right: 0px;
    top : 14%;
}
.read-icon.contact {
    right: 6px;
    top: 5px;
}

@media screen and (max-width:800px) {
    
}