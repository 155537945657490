.lightbooth-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.lightbooth-logo{
    width:140px;
}
.serialcode{
    width:50px;
}
.lightbooth-screen-container{
    border-radius: 17px;
    background-color: bisque;
    display: inline-block;
    width:350px;
    height:344px;
    position: relative;
}
.lightbooth-border{
    display: inline-block;
    background-image: url('./assets/hak12-lightbooth-screen.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
}
.live-icon{
    width:50px;
    position:absolute;
    bottom: 4%;
    right: 4%;
    animation: blink normal 1.5s infinite ease-in-out; 

}
@keyframes blink {
    0% {opacity:1;}
    50% {opacity:0;}
    100% {opacity:1;}
}
.switch-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
}
.switch-container .switch{
    width: 50px;
    height: 95px;
    display: inline-block;
    background-size: 100%;
    cursor: pointer;
    background-repeat: no-repeat;
}

.switch-container .switch.MA{
    background-position: center;
}
.lightbooth-caption{
    text-align: center;
}

.switch-container .switch.N.false{
    background-image : url('./assets/hak12-lightbooth-1n-inactive.png');
}

.switch-container .switch.N.true{
    background-image : url('./assets/hak12-lightbooth-1n-active.png');
}

.switch-container .switch.A.false{
    background-image : url('./assets/hak12-lightbooth-2a-inactive.png');
}

.switch-container .switch.A.true{
    background-image : url('./assets/hak12-lightbooth-2a-active.png');
}

.switch-container .switch.U.false{
    background-image : url('./assets/hak12-lightbooth-2u-inactive.png');
}

.switch-container .switch.U.true{
    background-image : url('./assets/hak12-lightbooth-2u-active.png');
}

.switch-container .switch.M.false{
    background-image : url('./assets/hak12-lightbooth-2m-inactive.png');
}

.switch-container .switch.M.true{
    background-image : url('./assets/hak12-lightbooth-2m-active.png');
}

.switch-container .switch.S.false{
    background-image : url('./assets/hak12-lightbooth-4s-inactive.png');
}

.switch-container .switch.S.true{
    background-image : url('./assets/hak12-lightbooth-4s-active.png');
}

.switch-container .switch.MA.false{
    background-image : url('./assets/hak12-lightbooth-manauto-auto.png');
}

.switch-container .switch.MA.true{
    background-image : url('./assets/hak12-lightbooth-manauto-man.png');
}